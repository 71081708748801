import React, { useState, useEffect } from "react";
import { Switch, HashRouter, Route, withRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import {
  FaThList,
  FaLifeRing,
  FaEnvelope,
  FaQuestionCircle,
  FaUserMd,
  FaStickyNote,
} from "react-icons/fa";
import { Auth } from "aws-amplify";

import "./App.css";
import logo from "./logo.svg";
import welcomePicture from "./welcome_picture.jpg";
import Upload from "./Upload";
import Login from "./Login";
import NotFound from "./NotFound";
import Sample from "./Sample";
import Krona from "./Krona";
import Samples from "./Samples";
import SpeciesAbundance from "./SpeciesAbundance";
import Signup from "./Signup";
import Account from "./Account";
import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";
import GoogleDoc from "./GoogleDoc";

const LogoutLink = withRouter(({ history, setAuthenticated }) => {
  const logout = async () => {
    await Auth.signOut();
    setAuthenticated(false);
    history.push("/");
  };
  return (
    <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
  );
});

const App = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [userAttributes, setUserAttributes] = useState(null);
  const authenticate = async () => {
    await Auth.currentSession();
    const user = await Auth.currentAuthenticatedUser();
    setUserAttributes(user.attributes);
    setAuthenticated(true);
  };
  const login = async (email, password) => {
    await Auth.signIn(email, password);
    authenticate();
  };
  const signUp = async (email, password) => {
    const newUser = await Auth.signUp({ username: email, password });
    return newUser;
  };
  const confirmSignUp = async (email, confirmationCode, activationCode) => {
    await Auth.confirmSignUp(email, confirmationCode, {
      clientMetadata: { activationCode }
    });
  };
  useEffect(() => {
    (async () => {
      if (!isAuthenticated) {
        console.log("checking authentication status...");
        try {
          await authenticate();
          console.log("was already authenticated");
        } catch (err) {
          console.log("not authenticated: " + err);
        }
      }
    })();
  }, [isAuthenticated]);
  return (
    <HashRouter>
      <Navbar fixed="top" id="navbar" bg="light">
        <Navbar.Brand>
          <img
            src={logo}
            height="55px"
            alt="ViennaLab"
            style={{ padding: ".5rem 1rem" }}
          />
        </Navbar.Brand>
        <Nav className="mr-auto"></Nav>
        <Nav>
          {isAuthenticated && (
            <LinkContainer to="/samples">
              <Nav.Link active={false} title="Samples">
                <FaThList />
              </Nav.Link>
            </LinkContainer>
          )}
          {isAuthenticated && (
            <LinkContainer to="/help">
              <Nav.Link active={false} title="Help">
                <FaLifeRing />
              </Nav.Link>
            </LinkContainer>
          )}
          {isAuthenticated && ( 
            <LinkContainer to="/about">
              <Nav.Link active={false} title="About">
                <FaQuestionCircle />
              </Nav.Link>
            </LinkContainer>
          )}
          {isAuthenticated && ( 
            <LinkContainer to="/release-notes">
              <Nav.Link active={false} title="Release notes">
                <FaStickyNote />
              </Nav.Link>
            </LinkContainer>
          )}
          <LinkContainer to="/contact">
            <Nav.Link active={false} title="Contact">
              <FaEnvelope />
            </Nav.Link>
          </LinkContainer>
          {isAuthenticated ? (
            <NavDropdown
              title={
                <span>
                  <FaUserMd /> {userAttributes.email}
                </span>
              }
            >
              <NavDropdown.Item href="#/account">Account</NavDropdown.Item>
              <LogoutLink setAuthenticated={setAuthenticated} />
            </NavDropdown>
          ) : (
            <>
              <LinkContainer to="/login">
                <Nav.Link active={false} title="Log in">
                  Log in
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/signup">
                <Nav.Link active={false} title="Sign up">
                  Sign up
                </Nav.Link>
              </LinkContainer>
            </>
          )}
        </Nav>
      </Navbar>
      <Container id="container" fluid>
        <Row id="content">
          <Col id="sidebar" className="bg-light" xs={2}> 
          <Nav as="nav" className="flex-column sticky-top sticky-offset">
              {isAuthenticated && (
                <>
                  <Nav.Item>
                    <LinkContainer to="/samples">
                      <Nav.Link active={false} className="text-dark">
                        Samples
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                  <Nav.Item>
                    <LinkContainer to="/upload">
                      <Nav.Link active={false} className="text-dark">
                        Upload
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                </>
              )}
              <div className="sticky-bottom-box">
                  <a href="https://euformatics.com" target="_blank" rel="noopener noreferrer">
                    <span className="text-muted">powered by</span><br />
                    <img alt="Euformatics" src="euformatics_logo.png" style={{ maxWidth: "120px"}} />
                  </a>
              </div>
            </Nav>
          </Col>
          <Col as="main" className="p-5">
            <Switch>
              <Route path="/samples" exact>
                {isAuthenticated && <Samples />}
              </Route>
              <Route path="/samples/:sampleName" exact>
                {isAuthenticated && <Sample />}
              </Route>
              <Route path="/samples/:sampleName/diagram" exact>
                {isAuthenticated && <Krona />}
              </Route>
              <Route path="/samples/:sampleName/:target/diagram" exact>
                {isAuthenticated && <Krona />}
              </Route>
              <Route path="/samples/:sampleName/abundance" exact>
                {isAuthenticated && <SpeciesAbundance />}
              </Route>
              <Route path="/samples/:sampleName/:target/abundance" exact>
                {isAuthenticated && <SpeciesAbundance />}
              </Route>
              <Route path="/login" exact>
                <Login isAuthenticated={isAuthenticated} login={login} />
              </Route>
              <Route path="/login/reset" exact>
                <ResetPassword />
              </Route>
              <Route path="/signup" exact>
                <Signup
                  signUp={signUp}
                  confirmSignUp={confirmSignUp}
                  login={login}
                />
              </Route>
              <Route path="/account" exact>
                {isAuthenticated && <Account />}
              </Route>
              <Route path="/account/password" exact>
                {isAuthenticated && <ChangePassword />}
              </Route>
              <Route path="/upload" exact>
                {isAuthenticated && <Upload />}
              </Route>
              <Route path="/terms" exact>
               <GoogleDoc src="https://docs.google.com/document/d/e/2PACX-1vRVmbPq4Q2Ez-wByzL9nBZMxQ9Rhn01LTTdyAh8ygKFsz6lsUq-olt5oolkZdG9_7DoVIawqZoeBtYu/pub?embedded=true" />
              </Route>
              <Route path="/privacy" exact>
                <GoogleDoc src="https://docs.google.com/document/d/e/2PACX-1vQb8W_Sa5lABNOx9PbvlDUT7KqWEnsaQpB0uzTNnn2dpD5bl8J2oYjG3E5htcQBNPm_j_H9F1Txkh-T/pub?embedded=true" />
              </Route>
              <Route path="/about" exact>
                {isAuthenticated && <GoogleDoc src="https://docs.google.com/document/d/e/2PACX-1vT45AcH5JyZ1wsfxL6NLkWh53yH8A-719ILOwh8MRUU70zC9JYArNjzKx2w9JfYV1fzhl6M2c2_G-9q/pub?embedded=true" />}
              </Route>
              <Route path="/release-notes" exact>
                {isAuthenticated && <GoogleDoc src="https://docs.google.com/document/d/e/2PACX-1vTzIROaqGLQ_v-KhyzpBhLRtxc8zdnZteWRhHomyZMxqKhjwFl05YS-jbRApvlawgRx-F_sQVKjqN45/pub?embedded=true" />}
              </Route>
              <Route path="/help" exact>
                {isAuthenticated && <GoogleDoc src="https://docs.google.com/document/d/e/2PACX-1vSHRDrELOSHe_D7_p2jYmCOa8DIoKHbtyqP7ggOTUIz6l4Of33_rntEZha2Q5OEEYmj7IfJYKv0hAzz/pub?embedded=true" />}
              </Route>
              <Route path="/contact" exact>
                <GoogleDoc src="https://docs.google.com/document/d/e/2PACX-1vQX0Z6X8ek2O0m4P0R3h8aqD8l2vLrNA16orhZkz90ep4XIcAGKA2Xy24OkA74VDgiEplDaIyOodBOt/pub?embedded=true" />
              </Route>
              <Route path="/" exact>
                {isAuthenticated ? (
                  <Samples />
                ) : (
                  <div>
                    <div className="card mx-auto" style={{width: "22rem"}}>
                      <img className="card-img-top" src={welcomePicture} alt="" />
                      <div className="card-body">
                        <h5 className="card-title">ViennaLab Microbiome Analysis</h5>
                        <p className="card-text">Welcome to the ViennaLab Microbiome Analysis Webtool.</p>
                        <a href="#/login" className="btn btn-primary">Log in</a>
                        <a href="#/signup" className="btn float-right">Sign up</a>
                      </div>
                    </div>
                  </div>
                )}
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Container>
    </HashRouter>
  );
};

export default App;
