import { API } from "aws-amplify";

const getResultsUrl = async (sampleName, path, target, filename) => {
  try {
    const init = {
      queryStringParameters: {}
    };
    if (target) {
      init.queryStringParameters["target"] = target
    }
    if (filename) {
      init.queryStringParameters["filename"] = filename.replace(/[^A-Za-z0-9._-]+/g, "-");
    }
    const response = await API.get(
      "mgp",
      `/samples/${sampleName}/file/${path}`,
      init
    );
    return response.url;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

export { getResultsUrl };
