import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { saveAs } from "file-saver";
import {
  FaRegFilePdf,
  FaSpinner,
  FaCaretUp,
  FaCaretRight,
  FaTable,
  FaFile,
  FaChartPie,
  FaEdit,
  FaSave,
} from "react-icons/fa";
import { isNil } from "ramda";
import moment from "moment";

import { getResultsUrl } from "./results";
import Range from "./Range";
import ReadBar from "./ReadBar";
import "./Sample.css";

const MISSING_VALUE = "N/A";

const PDF_LANGUAGE = {
  "en": "English",
  "de": "Deutsch",
};

/**
 * If the number of reads fed into classification is less than this, display a
 * warning message
 */
const REQUIRED_READS_V3V4 = 4000;
const REQUIRED_READS_ITS2 = 100;

const getDetails = async (sampleName) => {
  try {
    const response = await API.get("mgp", `/samples/${sampleName}`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

const patchDetails = async (sampleName, details) => {
  try {
    const response = await API.patch("mgp", `/samples/${sampleName}`, {
      body: details,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const getStats = async (sampleName, target) => {
  try {
    const init = {
      queryStringParameters: {}
    };
    if (target) {
      init.queryStringParameters["target"] = target;
    }
    const response = await API.get("mgp", `/samples/${sampleName}/stats`, init);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

const getFastqStats = async (sampleName) => {
  try {
    const response = await API.get("mgp", `/samples/${sampleName}/fastq-stats`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

const getMetadata = async (sampleName) => {
  try {
    const response = await API.get("mgp", `/samples/${sampleName}/metadata`);
    if (response === null) {
      return {};
    }
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return {};
    }
    throw error;
  }
};

const getBacterialPhyla = async (sampleName, target) => {
  try {
    const init = {
      queryStringParameters: {}
    };
    if (target) {
      init.queryStringParameters["target"] = target;
    }
    const response = await API.get(
      "mgp",
      `/samples/${sampleName}/bacterial-phyla`,
      init
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

const getEnterotype = async (sampleName, target) => {
  try {
    const init = {
      queryStringParameters: {}
    };
    if (target) {
      init.queryStringParameters["target"] = target;
    }
    const response = await API.get("mgp", `/samples/${sampleName}/enterotype`, init);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

const DiversitySection = ({target, sampleName, stats}) => {
  const defaultLabels = [
    "Shannon",
  ];
  const allLabels = [
    "Shannon",
    "ACE",
    "Chao1",
    "Jackknife",
    "Simpson",
    "NPShannon",
  ]
  const [labels, setLabels] = useState(defaultLabels);
  const handleMore = (event) => {
    event.preventDefault();
    if (labels.length === defaultLabels.length) {
      setLabels(allLabels);
    } else {
      setLabels(defaultLabels);
    }
  };
  return (
    stats && (
      <section>
        <h4>{target === "ITS2" ? "Fungal " : "Bacterial "}Diversity</h4>
        <Table>
          <tbody>
            {labels.map((stat) => (
              <tr className="d-flex" key={stats[stat].statistic}>
                <td className="col-2">{stats[stat].statistic}</td>
                <td className="col-1">
                  {isNil(stats[stat].value)
                    ? MISSING_VALUE
                    : stats[stat].value.toFixed(1)}
                </td>
                <td className="col-3">
                  {stat === "Shannon" && !isNil(stats[stat].value) && (
                    <Range
                      value={stats[stat].value}
                      dispMin={0}
                      refMin={4}
                      refMax={10}
                      dispMax={10}
                      delta={0.1}
                    />
                  )}
                </td>
                <td className="col-6">
                  {stat === "Shannon" && (
                    <dl className="compact">
                      <dt>
                        <small>&lt; 3</small>
                      </dt>{" "}
                      <dd>
                        <small>limited diversity</small>
                      </dd>
                      <dt>
                        <small>3–4</small>
                      </dt>{" "}
                      <dd>
                        <small>medium diversity</small>
                      </dd>
                      <dt>
                        <small>&gt; 4</small>
                      </dt>{" "}
                      <dd>
                        <small>good diversity</small>
                      </dd>
                    </dl>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="d-flex">
              <td className="col-12" colSpan="4">
                <a href={`#/samples/${sampleName}`} onClick={handleMore}>
                  {labels.length === 1 ? (
                    <>
                      <FaCaretRight />
                      Show more
                    </>
                  ) : (
                    <>
                      <FaCaretUp />
                      Show less
                    </>
                  )}
                </a>
              </td>
            </tr>
          </tfoot>
        </Table>
      </section>
    )
  );
};

const FBSection = ({stats}) => {
  return (
    stats && stats["F/B ratio"] && (
      <section>
        <h4>Firmicutes/Bacteroidetes</h4>
        <Table>
          <tbody>
            <tr className="d-flex">
              <td className="col-2">{stats["F/B ratio"].statistic}</td>
              <td className="col-1">
                {isNil(stats["F/B ratio"].value)
                  ? MISSING_VALUE
                  : stats["F/B ratio"].value.toFixed(1)}
              </td>
              <td className="col-3">
                {!isNil(stats["F/B ratio"].value) && (
                  <Range
                    value={stats["F/B ratio"].value}
                    dispMin={0}
                    refMin={0}
                    refMax={1.5}
                    dispMax={5}
                    delta={0.2}
                  />
                )}
              </td>
              <td className="col-6">
                <dl className="compact">
                  <dt>
                    <small>≤ 1.5</small>
                  </dt>{" "}
                  <dd>
                    <small>balanced microbiome composition</small>
                  </dd>
                  <dt>
                    <small>1.5–3</small>
                  </dt>{" "}
                  <dd>
                    <small>imbalanced microbiome composition</small>
                  </dd>
                  <dt>
                    <small>≥ 3</small>
                  </dt>{" "}
                  <dd>
                    <small>unfavorable microbiome composition</small>
                  </dd>
                </dl>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr className="d-flex">
              <td className="col-12" colSpan="4"></td>
            </tr>
          </tfoot>
        </Table>
      </section>
    )
  );
};

const EnterotypeSection = ({ enterotype }) => {
  return (
    enterotype && (
      <section>
        <h4>
          Enterotype{" "}
          {enterotype.enterotype && !enterotype.ambiguous && (
            <span
              style={{
                border: "1px solid",
                paddingLeft: "0.5em",
                paddingRight: "0.5em",
              }}
            >
              {enterotype.enterotype}
            </span>
          )}
        </h4>
        {enterotype.enterotype &&
          !enterotype.ambiguous &&
          (String(enterotype.enterotype) === "1" ? (
            <span>Bacteroides-type</span>
          ) : String(enterotype.enterotype) === "2" ? (
            <span>Prevotella-type</span>
          ) : String(enterotype.enterotype) === "3" ? (
            <span>Ruminococcus-type</span>
          ) : (
            <></>
          ))}
        {enterotype.ambiguous && (
          <span>Unambiguous assignment of enterotype not possible</span>
        )}
      </section>
    )
  );
};

const BacterialPhylaSection = ({ bacterialPhyla }) => {
  return (
    bacterialPhyla && (
      <section>
        <h4>Bacterial Phyla</h4>
        <table>
          <thead>
            <tr>
              <th>Phylum</th>
              <th>Sample Value [%]</th>
              <th></th>
              <th>Reference Range [%]</th>
            </tr>
          </thead>
          <tbody>
            {bacterialPhyla.map((element, i) => (
              <tr key={`bacterial-phylum-${i}`}>
                <td>{element["phylum"]}</td>
                <td>{element["proportion_all(%)"].toFixed(2)}</td>
                <td>
                  <Range
                    value={element["proportion_all(%)"]}
                    dispMin={element["disp_min"]}
                    refMin={element["ref_min"]}
                    refMax={element["ref_max"]}
                    dispMax={element["disp_max"]}
                  />
                </td>
                <td>
                  {element.hasOwnProperty("ref_min") &&
                    element.hasOwnProperty("ref_max") &&
                    `${element["ref_min"]} - ${element["ref_max"]}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    )
  );
};

const AbundanceLinksSection = ({ sampleName, target, resultsUrl, resultsCsvUrl }) => {
  return (
    (resultsUrl || resultsCsvUrl) && (
      <section>
        <h4>{target === "ITS2" ? "Fungal " : "Bacterial "}Species abundance</h4>
        <a
          href={target ? `#/samples/${sampleName}/${target}/abundance` : `#/samples/${sampleName}/abundance`}
          title="View species abundance table"
        >
          <FaTable /> Table
        </a>{" "}
        |{" "}
        {resultsUrl ? (
          <a
            href={resultsUrl}
            title="Download species abundance table as a tab-separated text file"
          >
            <FaFile /> Download
          </a>
        ) : (
          <a
            href={resultsCsvUrl}
            title="Download species abundance table as a comma-separated file"
          >
            <FaFile /> Download
          </a>
        )}{" "}
        |{" "}
        <a
          href={target ? `#/samples/${sampleName}/${target}/diagram`: `#/samples/${sampleName}/diagram`}
          title="View interactive abundance diagram"
        >
          <FaChartPie /> Diagram
        </a>
      </section>
    )
  );
};

const PieDiagramSections = ({ target, generaUrl, speciesUrl, phylaUrl, classesUrl, ordersUrl, familiaeUrl }) => {
  return (
    <>
      {generaUrl && (
        <section>
          <h4>{target === "ITS2" ? "Fungal " : "Bacterial "}Genus and Species level composition</h4>
          <Tabs defaultActiveKey="genus">
            <Tab eventKey="genus" title="Genus">
              <img src={generaUrl} alt="Genus abundance" />
            </Tab>
            <Tab eventKey="species" title="Species">
              <img src={speciesUrl} alt="Species abundance" />
            </Tab>
          </Tabs>
        </section>
      )}
      {phylaUrl && (
        <section>
          <h4>{target === "ITS2" ? "Fungal " : "Bacterial "}Phylum/Class/Order/Family level composition</h4>
          <Tabs defaultActiveKey="phylum">
            <Tab eventKey="phylum" title="Phylum">
              <img src={phylaUrl} alt="Phylum abundance" />
            </Tab>
            <Tab eventKey="class" title="Class">
              <img src={classesUrl} alt="Class abundance" />
            </Tab>
            <Tab eventKey="order" title="Order">
              <img src={ordersUrl} alt="Order abundance" />
            </Tab>
            <Tab eventKey="family" title="Family">
              <img src={familiaeUrl} alt="Family abundance" />
            </Tab>
          </Tabs>
        </section>
      )}
    </>
  );
};

const Sample = (props) => {
  const { sampleName } = useParams();
  const [target, setTarget] = useState(null);
  const [resultsUrl, setResultsUrl] = useState(null);
  const [resultsCsvUrl, setResultsCsvUrl] = useState(null);
  const [generaUrl, setGeneraUrl] = useState(null);
  const [speciesUrl, setSpeciesUrl] = useState(null);
  const [phylaUrl, setPhylaUrl] = useState(null);
  const [classesUrl, setClassesUrl] = useState(null);
  const [ordersUrl, setOrdersUrl] = useState(null);
  const [familiaeUrl, setFamiliaeUrl] = useState(null);
  const [stats, setStats] = useState(null);
  const [fastqStats, setFastqStats] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [bacterialPhyla, setBacterialPhyla] = useState(null);
  const [enterotype, setEnterotype] = useState(null);
  const [isPdfLoading, setPdfLoading] = useState(false);
  const [details, setDetails] = useState({});
  const setSubmitter = (submitter) =>
    setDetails({ ...details, ...{ submitter } });
  const setProject = (project) =>
    setDetails({ ...details, ...{ project } });
  const setInfo = (info) => setDetails({ ...details, ...{ info } });
  const [isEditingProject, setEditingProject] = useState(false);
  const [isEditingSubmitter, setEditingSubmitter] = useState(false);
  const [isEditingInfo, setEditingInfo] = useState(false);
  const [pdfLang, setPdfLang] = useState("en");
  const [isTargetLoading, setTargetLoading] = useState(true);
  const fetchResultsUrl = async (sampleName, target) =>
    setResultsUrl(
      await getResultsUrl(
        sampleName,
        "output.txt",
        target,
        `${sampleName}_species_abundance.txt`
      )
    );
  const fetchResultsCsvUrl = async (sampleName, target) =>
    setResultsCsvUrl(
      await getResultsUrl(
        sampleName,
        "results.csv",
        target,
        `${sampleName}_species_abundance.csv`
      )
    );
  const fetchGeneraUrl = async (sampleName, target) =>
    setGeneraUrl(await getResultsUrl(sampleName, "output_genera.png", target));
  const fetchSpeciesUrl = async (sampleName, target) =>
    setSpeciesUrl(await getResultsUrl(sampleName, "output_species.png", target));
  const fetchPhylaUrl = async (sampleName, target) =>
    setPhylaUrl(await getResultsUrl(sampleName, "output_phyla.png", target));
  const fetchClassesUrl = async (sampleName, target) =>
    setClassesUrl(await getResultsUrl(sampleName, "output_classes.png", target));
  const fetchOrdersUrl = async (sampleName, target) =>
    setOrdersUrl(await getResultsUrl(sampleName, "output_orders.png", target));
  const fetchFamiliaeUrl = async (sampleName, target) =>
    setFamiliaeUrl(await getResultsUrl(sampleName, "output_familiae.png", target));
  const fetchStats = async (sampleName, target) => setStats(await getStats(sampleName, target));
  const fetchFastqStats = async (sampleName) =>
    setFastqStats(await getFastqStats(sampleName));
  const fetchMetadata = async (sampleName) => {
    const newMetadata = await getMetadata(sampleName);
    if (newMetadata && newMetadata.targets && newMetadata.targets.length > 0) {
      // default to showing the first target
      const newTarget = newMetadata.targets.includes("V3V4") ? "V3V4" : newMetadata.targets[0];
      console.log(`metadata fetched; setting target to ${newTarget}`); // XXX DEBUG
      setTarget(newTarget); 
    } else {
      console.log(`metadata fetched; not setting target`); // XXX DEBUG
    }
    setMetadata(newMetadata);
  };
  const fetchDetails = async (sampleName) =>
    setDetails(await getDetails(sampleName));
  const fetchBacterialPhyla = async (sampleName, target) =>
    setBacterialPhyla(await getBacterialPhyla(sampleName, target));
  const fetchEnterotype = async (sampleName, target) =>
    setEnterotype(await getEnterotype(sampleName, target));
  const downloadPdf = async (event) => {
    setPdfLoading(true);
    event.preventDefault();
    const blob = await API.get("mgp", `/samples/${sampleName}/pdf`, {
      queryStringParameters: {
        lang: pdfLang,
      },
      headers: {
        Accept: "application/pdf",
      },
      responseType: "blob",
    });
    saveAs(blob, `${sampleName}.pdf`);
    setPdfLoading(false);
  };

  const handleProjectChanged = (event) => {
    event.preventDefault();
    setProject(event.target.value);
  };
  const handleProjectBlurred = async (event) => {
    setEditingSubmitter(false);
    await patchDetails(sampleName, details);
  };
  const handleProjectEditClicked = (event) => {
    setEditingProject(true);
    document.getElementById("project").focus();
    document.getElementById("project").select();
  };
  const handleProjectKeyPress = async (event) => {
    if (event.charCode === 13) {
      event.preventDefault();
      setEditingProject(false);
      await patchDetails(sampleName, details);
    }
  };

  const handlePdfLangSelected = (lang) => {
    setPdfLang(lang);
  };
  const handleSubmitterChanged = (event) => {
    event.preventDefault();
    setSubmitter(event.target.value);
  };
  const handleSubmitterBlurred = async (event) => {
    setEditingSubmitter(false);
    await patchDetails(sampleName, details);
  };
  const handleSubmitterEditClicked = (event) => {
    setEditingSubmitter(true);
    document.getElementById("submitter").focus();
    document.getElementById("submitter").select();
  };
  const handleSubmitterSave = async (event) => {
    setEditingSubmitter(false);
    await patchDetails(sampleName, details);
  };
  const handleSubmitterKeyPress = async (event) => {
    if (event.charCode === 13) {
      event.preventDefault();
      setEditingSubmitter(false);
      await patchDetails(sampleName, details);
    }
  };

  const handleInfoChanged = (event) => {
    event.preventDefault();
    setInfo(event.target.value);
  };
  const handleInfoBlurred = async (event) => {
    setEditingInfo(false);
    await patchDetails(sampleName, details);
  };
  const handleInfoEditClicked = (event) => {
    setEditingInfo(true);
    document.getElementById("info").focus();
    document.getElementById("info").select();
  };
  const handleInfoSave = async (event) => {
    setEditingInfo(false);
    await patchDetails(sampleName, details);
  };
  const handleTargetSelect = (key) => {
    if (metadata && metadata.targets && metadata.targets.includes(key)) {
      setTargetLoading(true);
      setTarget(key);
    } else {
      console.warn(`No target ${key}; not setting`);
    }
  };
  useEffect(() => {
    (async () => {
      // first, fetch sample level info, includign metadata
      try {
        await Promise.all([
          fetchMetadata(sampleName),
          fetchDetails(sampleName),
          fetchFastqStats(sampleName),
        ]);
      } catch (error) {
        console.log(error);
        alert(error);
      }
    })();
  }, [sampleName]);
  useEffect(() => {
    (async () => {
      // then, fetch the rest
      try {
        if (metadata !== null) {
          await Promise.all([
            fetchStats(sampleName, target),
            fetchResultsUrl(sampleName, target),
            fetchResultsCsvUrl(sampleName, target),
            fetchGeneraUrl(sampleName, target),
            fetchSpeciesUrl(sampleName, target),
            fetchPhylaUrl(sampleName, target),
            fetchClassesUrl(sampleName, target),
            fetchOrdersUrl(sampleName, target),
            fetchFamiliaeUrl(sampleName, target),
            fetchBacterialPhyla(sampleName, target),
            fetchEnterotype(sampleName, target),
          ]);
          setTargetLoading(false);
        }
      } catch (error) {
        console.log(error);
        alert(error);
      }
    })();
  }, [sampleName, metadata, target]);
  return (
    <>
      {metadata && details && <div className="float-right">
        <Dropdown as={ButtonGroup}>
          <Button
            variant="primary"
            disabled={isPdfLoading}
            onClick={downloadPdf}
          >
            {isPdfLoading ? <FaSpinner className="icon-spin" /> : <FaRegFilePdf />}{" "}
            Download PDF
          </Button>
          <Dropdown.Toggle split variant="primary">
            {PDF_LANGUAGE[pdfLang]}&nbsp;&nbsp;
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              Object.keys(PDF_LANGUAGE).map((lang) => 
                <Dropdown.Item key={lang}
                  active={lang === pdfLang}
                  eventKey={lang}
                  onSelect={handlePdfLangSelected}>
                  {PDF_LANGUAGE[lang]}
                </Dropdown.Item>)
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>}
      <h3>Sample {sampleName}</h3>
      {metadata && details && (
        <>
          <section>
            <Table>
              <tbody>
                <tr className="d-flex">
                  <td className="col-2">Sample ID:</td>
                  <td className="col-6">{sampleName}</td>
                  <td className="col-1"></td>
                </tr>
                <tr className="d-flex">
                  <td className="col-2">Date of Analysis:</td>
                  <td className="col-6">
                    {details &&
                      details.date &&
                      moment(details.date).format("YYYY-MM-DD")}
                  </td>
                  <td className="col-1"></td>
                </tr>
                {metadata && metadata.MGP_VERSION && (
                <tr className="d-flex">
                  <td className="col-2">Pipeline version:</td>
                  <td className="col-6">
                    {metadata &&
                      metadata.MGP_VERSION}
                  </td>
                  <td className="col-1"></td>
                </tr>)}
                <tr className="d-flex">
                  <td className="col-2">Project:</td>
                  <td className="col-6">
                    <input
                      id="project"
                      type="text"
                      size="50"
                      name="project"
                      maxLength="60"
                      style={{ width: "100%" }}
                      readOnly={!isEditingProject}
                      value={(details && details.project) || ""}
                      onChange={handleProjectChanged}
                      onBlur={handleProjectBlurred}
                      onKeyPress={handleProjectKeyPress}
                    />
                  </td>
                  <td className="col-1">
                    {!isEditingProject && (
                      <Button
                        variant="Light"
                        title="Edit"
                        onClick={handleProjectEditClicked}
                      >
                        <FaEdit />
                      </Button>
                    )}
                  </td>
                </tr>
                <tr className="d-flex">
                  <td className="col-2">Submitter:</td>
                  <td className="col-6">
                    <input
                      id="submitter"
                      type="text"
                      size="50"
                      name="submitter"
                      maxLength="60"
                      style={{ width: "100%" }}
                      readOnly={!isEditingSubmitter}
                      value={(details && details.submitter) || ""}
                      onChange={handleSubmitterChanged}
                      onBlur={handleSubmitterBlurred}
                      onKeyPress={handleSubmitterKeyPress}
                    />
                  </td>
                  <td className="col-1">
                    {!isEditingSubmitter ? (
                      <Button
                        variant="Light"
                        title="Edit"
                        onClick={handleSubmitterEditClicked}
                      >
                        <FaEdit />
                      </Button>
                    ) : (
                      <Button
                        variant="Light"
                        title="Save"
                        onClick={handleSubmitterSave}
                      >
                        <FaSave />
                      </Button>
                    )}
                  </td>
                </tr>
                <tr className="d-flex">
                  <td className="col-2">Additional Information:</td>
                  <td className="col-6">
                    <textarea
                      id="info"
                      type="textarea"
                      name="info"
                      rows="3"
                      cols="50"
                      maxLength="256"
                      style={{ resize: "none", width: "100%" }}
                      readOnly={!isEditingInfo}
                      value={(details && details.info) || ""}
                      onChange={handleInfoChanged}
                      onBlur={handleInfoBlurred}
                    />
                  </td>
                  <td className="col-1">
                    {!isEditingInfo ? (
                      <Button
                        variant="Light"
                        title="Edit"
                        onClick={handleInfoEditClicked}
                      >
                        <FaEdit />
                      </Button>
                    ) : (
                      <Button
                        variant="Light"
                        title="Save"
                        onClick={handleInfoSave}
                      >
                        <FaSave />
                      </Button>
                    )}
                  </td>
                </tr>
                {fastqStats && (
                  <tr className="d-flex">
                    <td className="col-2">Total reads:</td>
                    <td className="col-6">
                      {fastqStats[0].num_seqs}
                    </td>
                    <td className="col-1"></td>
                  </tr>)}
              </tbody>
            </Table>
          </section>
        </>
      )}
      <Tabs defaultActiveKey={target || "V3V4"} onSelect={handleTargetSelect}>
        {metadata && (
          <Tab eventKey="V3V4" title="Bacterial" style={{visibility: isTargetLoading ? "hidden" : "visible"}}>
            {stats && <section>
              <p>Reads passing QC: {stats["Number of reads"].value}</p>
              {stats["Number of reads"].value < REQUIRED_READS_V3V4 && (
                <div className="alert alert-warning" role="alert" style={{width: 400}}>
                  Warning: Small number of merged reads (&lt;{REQUIRED_READS_V3V4}) found after QC. Low accuracy and sensitivity, especially for low abundant species, might be observed.
                </div>
              )}
              <p>
                <ReadBar
                  width={400}
                  height={20}
                  value={
                    (stats["Number of reads"].value -
                      stats["Number of unclassified reads"].value) /
                    stats["Number of reads"].value
                  }
                />
              </p>
              <p>
                Reads passing QC identified at species level:{" "}
                {(
                  (100 *
                    (stats["Number of reads"].value -
                      stats["Number of unclassified reads"].value)) /
                  stats["Number of reads"].value
                ).toFixed(1)}
                %
              </p>
            </section>}
            <AbundanceLinksSection sampleName={sampleName} target={target} resultsUrl={resultsUrl} resultsCsvUrl={resultsCsvUrl} />
            <PieDiagramSections target={target} generaUrl={generaUrl} speciesUrl={speciesUrl}
              phylaUrl={phylaUrl} classesUrl={classesUrl} ordersUrl={ordersUrl} familiaeUrl={familiaeUrl} />
            <BacterialPhylaSection bacterialPhyla={bacterialPhyla} />
            <DiversitySection target={target} sampleName={sampleName} stats={stats} />
            <FBSection stats={stats} />
            <EnterotypeSection enterotype={enterotype} />
          </Tab>
        )}
        {metadata && metadata.targets && metadata.targets.includes("ITS2") && (
          <Tab eventKey="ITS2" title="Fungal" style={{visibility: isTargetLoading ? "hidden" : "visible"}}>
            {stats && <section>
              <p>Reads passing QC: {stats["Number of reads"].value}</p>
              {stats["Number of reads"].value < REQUIRED_READS_ITS2 && (
                <div className="alert alert-warning" role="alert" style={{width: 400}}>
                  Warning: Small number of merged reads (&lt;{REQUIRED_READS_ITS2}) found after QC. Low accuracy and sensitivity, especially for low abundant species, might be observed.
                </div>
              )}
              <p>
                <ReadBar
                  width={400}
                  height={20}
                  value={
                    (stats["Number of reads"].value -
                      stats["Number of unclassified reads"].value) /
                    stats["Number of reads"].value
                  }
                />
              </p>
              <p>
                Reads passing QC identified at species level:{" "}
                {(
                  (100 *
                    (stats["Number of reads"].value -
                      stats["Number of unclassified reads"].value)) /
                  stats["Number of reads"].value
                ).toFixed(1)}
                %
              </p>
            </section>}
            <AbundanceLinksSection sampleName={sampleName} target={target} resultsUrl={resultsUrl} resultsCsvUrl={resultsCsvUrl} />
            <PieDiagramSections target={target} generaUrl={generaUrl} speciesUrl={speciesUrl}
                phylaUrl={phylaUrl} classesUrl={classesUrl} ordersUrl={ordersUrl} familiaeUrl={familiaeUrl} />
          </Tab>
        )}
      </Tabs>

    </>
  );
};

export default Sample;
