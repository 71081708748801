import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getResultsUrl } from "./results";

const Krona = () => {
  const { sampleName, target } = useParams();
  const [kronaUrl, setKronaUrl] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const kronaUrl = await getResultsUrl(sampleName, "results.html", target);
        setKronaUrl(kronaUrl);
      } catch (error) {
        console.log(error);
        alert(error);
      }
    })();
  }, [sampleName, target]);
  return (
    <>
      <h3>Sample {sampleName}</h3>
      {kronaUrl && (
        <section>
          <iframe
            title="Krona"
            src={kronaUrl}
            width="100%"
            height="1000"
            frameborder="0"
          />
        </section>
      )}
    </>
  );
};

export default Krona;
